import React from 'react';
import { motion } from 'framer-motion';

const GameTutorial = ({ onClose }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.8 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.8 }}
    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
  >
    <div className="bg-white p-8 rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Spielmechaniken erklärt</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <ul className="list-disc pl-5">
        <li>Zu Beginn des Spiels können die Kosten die Einnahmen übersteigen, was zu vorübergehenden Verlusten führt.</li>
        <li>Mit der Zeit werden Ihre Investitionen in Technologien und Mitarbeiter Früchte tragen und die Gewinne steigen.</li>
        <li>Die Anzahl der Mitarbeiter wächst mit dem Gesamtumsatz Ihres Unternehmens über alle Jahre hinweg.</li>
        <li>Das Erscheinungsbild Ihres Unternehmens (Hintergrundbild) verbessert sich basierend auf Ihrem Gesamterfolg, nicht nur auf dem aktuellen Jahr.</li>
        <li>Kundenzufriedenheit und Mitarbeiterzufriedenheit haben einen großen Einfluss auf Ihren Erfolg. Investieren Sie in Technologien, um diese zu verbessern!</li>
        <li>Beobachten Sie die zufälligen Ereignisse genau, sie können sowohl positive als auch negative Auswirkungen auf Ihr Unternehmen haben.</li>
        <li>Planen Sie Ihre Technologie-Investitionen sorgfältig. Manche Technologien erfordern Vorgängertechnologien, bevor sie implementiert werden können.</li>
        <li>Am Ende jedes Geschäftsjahres erhalten Sie einen detaillierten Bericht über Ihre Unternehmensleistung und die Auswirkungen Ihrer Technologie-Investitionen.</li>
      </ul>
    </div>
  </motion.div>
);

export default GameTutorial;