import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import StartScreen from './StartScreen';
import TechnologyTree from './TechnologyTree';
import CompanyMetrics from './CompanyMetrics';
import PerformanceChart from './PerformanceChart';
import EventDisplay from './EventDisplay';
import AutomationLevel from './AutomationLevel';
import TechInfoPopup from './TechInfoPopup';
import YearEndReport from './YearEndReport';
import BusinessStatistics from './BusinessStatistics';
import GameTutorial from './GameTutorial';
import ChallengePopup from './ChallengePopup';
import SalaryCosts from './SalaryCosts';
import { technologyTreeData } from './technologyTreeData';
import { events } from './events';
import * as AnalyticsService from '../services/analyticsService';

const CompanySimulation = () => {
  const [gameStarted, setGameStarted] = useState(false);
  const [company, setCompany] = useState({
    name: '',
    industry: '',
    revenue: 0,
    profit: 0,
    customerSatisfaction: 50,
    employeeSatisfaction: 50,
    automationLevel: 0,
    employees: 5,
  });
  const [implementedTechnologies, setImplementedTechnologies] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [historicalData, setHistoricalData] = useState([]);
  const [eventHistory, setEventHistory] = useState([]);
  const [selectedTech, setSelectedTech] = useState(null);
  const [growthStage, setGrowthStage] = useState(1);
  const [showYearEndReport, setShowYearEndReport] = useState(false);
  const [previousYearData, setPreviousYearData] = useState(null);
  const [newEvent, setNewEvent] = useState(null);
  const [yearlyRevenue, setYearlyRevenue] = useState(0);
  const [yearlyProfit, setYearlyProfit] = useState(0);
  const [allYearsData, setAllYearsData] = useState([]);
  const [showStatistics, setShowStatistics] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [gameDay, setGameDay] = useState(0);
  const [totalAssets, setTotalAssets] = useState(200000);
  const [isYearEnd, setIsYearEnd] = useState(false);
  const [currentChallenge, setCurrentChallenge] = useState(null);
  const [showChallenge, setShowChallenge] = useState(false);
  const [lastChallengeDay, setLastChallengeDay] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [averageSalary, setAverageSalary] = useState(5000);

  useEffect(() => {
    AnalyticsService.logPageView();
    const savedGame = localStorage.getItem('officeAutomationGame');
    if (savedGame) {
      const parsedGame = JSON.parse(savedGame);
      setGameStarted(true);
      setCompany(parsedGame.company);
      setImplementedTechnologies(parsedGame.implementedTechnologies);
      setCurrentDate(new Date(parsedGame.currentDate));
      setHistoricalData(parsedGame.historicalData);
      setEventHistory(parsedGame.eventHistory);
      setGrowthStage(parsedGame.growthStage);
      setAllYearsData(parsedGame.allYearsData);
      setGameDay(parsedGame.gameDay);
      setTotalAssets(parsedGame.totalAssets);
      setYearlyRevenue(parsedGame.yearlyRevenue);
      setYearlyProfit(parsedGame.yearlyProfit);
      setTotalPoints(parsedGame.totalPoints || 0);
      AnalyticsService.logEvent('Game', 'Load', 'Saved Game');
    }
  }, []);

  useEffect(() => {
    if (gameStarted) {
      const gameState = {
        company,
        implementedTechnologies,
        currentDate,
        historicalData,
        eventHistory,
        growthStage,
        allYearsData,
        gameDay,
        totalAssets,
        yearlyRevenue,
        yearlyProfit,
        totalPoints,
      };
      localStorage.setItem('officeAutomationGame', JSON.stringify(gameState));
    }
  }, [company, implementedTechnologies, currentDate, historicalData, eventHistory, growthStage, allYearsData, gameDay, totalAssets, yearlyRevenue, yearlyProfit, totalPoints]);

  const startGame = (companyName, industry) => {
    setGameStarted(true);
    const startCapital = industry.startCapital;
    setCompany(prev => ({
      ...prev,
      name: companyName,
      industry: industry.id,
      revenue: startCapital * 0.002, // Täglicher Umsatz als 0.2% des Startkapitals
      profit: 0,
      employees: 5,
    }));
    setTotalAssets(startCapital);
    setYearlyRevenue(0);
    setYearlyProfit(0);
    AnalyticsService.logGameStart();
    AnalyticsService.setUserProperty('companyName', companyName);
    AnalyticsService.setUserProperty('industry', industry.id);
  };

  const handleSelectTech = (tech) => {
    setSelectedTech(tech);
    AnalyticsService.logEvent('Technology', 'Select', tech.id);
  };

  const handleImplementTechnology = (techId, isFree = false) => {
    const tech = technologyTreeData[techId];
    if (!isFree && totalAssets < tech.cost) {
      alert('Nicht genug Vermögen zur Implementierung dieser Technologie!');
      return;
    }

    if (tech.dependencies.some(dep => !implementedTechnologies.includes(dep))) {
      alert('Sie müssen zuerst die Vorgängertechnologien implementieren!');
      return;
    }

    const newAutomationLevel = Math.min(100, company.automationLevel + (tech.effect.automation || 0));

    setCompany(prev => ({
      ...prev,
      customerSatisfaction: Math.min(100, prev.customerSatisfaction + (tech.effect.customerSatisfaction || 0)),
      employeeSatisfaction: Math.min(100, prev.employeeSatisfaction + (tech.effect.employeeSatisfaction || 0)),
      automationLevel: newAutomationLevel,
    }));

    if (!isFree) {
      setTotalAssets(prev => prev - tech.cost);
    }
    setImplementedTechnologies(prev => [...prev, techId]);
    setSelectedTech(null);
    AnalyticsService.logTechnologyImplementation(techId);
  };

  const triggerRandomEvent = () => {
    const eventProbability = 0.05;
    if (Math.random() < eventProbability) {
      const randomEvent = events[Math.floor(Math.random() * events.length)];
      handleEvent(randomEvent);
    }
  };

  const handleEvent = (event) => {
    const newCompany = { ...company };
    let effectDescription = [];

    Object.entries(event.effect).forEach(([key, value]) => {
      switch (key) {
        case 'umsatz':
          const umsatzChange = newCompany.revenue * value;
          newCompany.revenue *= (1 + value);
          effectDescription.push(`Umsatz: ${value > 0 ? '+' : ''}${(value * 100).toFixed(2)}% (${formatCurrency(umsatzChange)})`);
          break;
        case 'kundenzufriedenheit':
          newCompany.customerSatisfaction = Math.min(100, Math.max(0, newCompany.customerSatisfaction + value));
          effectDescription.push(`Kundenzufriedenheit: ${value > 0 ? '+' : ''}${value}`);
          break;
        case 'mitarbeiterzufriedenheit':
          newCompany.employeeSatisfaction = Math.min(100, Math.max(0, newCompany.employeeSatisfaction + value));
          effectDescription.push(`Mitarbeiterzufriedenheit: ${value > 0 ? '+' : ''}${value}`);
          break;
        case 'strafe':
          let penalty;
          if (typeof value === 'function') {
            penalty = value(newCompany) * totalAssets;
          } else {
            penalty = totalAssets * value;
          }
          setTotalAssets(prev => Math.max(0, prev - penalty));
          effectDescription.push(`Strafe: -${formatCurrency(penalty)}`);
          break;
      }
    });

    setCompany(newCompany);
    setEventHistory(prev => [...prev, {
      ...event,
      date: currentDate.toISOString(),
      effects: effectDescription.join(', ')
    }]);
    setNewEvent(event);
    setTimeout(() => setNewEvent(null), 5000);
    AnalyticsService.logRandomEvent(event.id);
  };

  const generateChallenge = () => {
    const challenges = [
      {
        title: "Office 365 Optimierung",
        description: "Ihr Unternehmen hat Probleme mit der Zusammenarbeit. Optimieren Sie die Nutzung von Office 365.",
        options: [
          { text: "Schulung durchführen", points: 7 },
          { text: "Neue Features aktivieren", points: 5 },
          { text: "Experten konsultieren", points: 10 },
          { text: "Ignorieren und hoffen, dass sich das Problem von selbst löst", points: 2 }
        ],
        expertTip: "Ein Experte würde empfehlen, zuerst eine gründliche Analyse durchzuführen und dann maßgeschneiderte Schulungen anzubieten."
      },
      // ... weitere Herausforderungen hier
    ];
    
    const newChallenge = challenges[Math.floor(Math.random() * challenges.length)];
    setCurrentChallenge(newChallenge);
    setShowChallenge(true);
    setLastChallengeDay(gameDay);
    AnalyticsService.logChallengeStart(newChallenge.title);
  };

  const handleChallengeReward = (points) => {
    setTotalPoints(prev => prev + points);
    AnalyticsService.logChallengeComplete(currentChallenge.title, points);

    if (points >= 8) {
      const availableTechs = Object.keys(technologyTreeData).filter(techId => 
        !implementedTechnologies.includes(techId) &&
        technologyTreeData[techId].dependencies.every(dep => implementedTechnologies.includes(dep))
      );
      if (availableTechs.length > 0) {
        const randomTechId = availableTechs[Math.floor(Math.random() * availableTechs.length)];
        const tech = technologyTreeData[randomTechId];
        handleImplementTechnology(randomTechId, true);
        return {
          message: `Großartig! Sie haben die Technologie "${tech.name}" kostenlos implementiert.`,
          effects: `Auswirkungen:\n` +
            `${tech.effect.productivity ? `- Produktivität: +${tech.effect.productivity}%\n` : ''}` +
            `${tech.effect.customerSatisfaction ? `- Kundenzufriedenheit: +${tech.effect.customerSatisfaction}\n` : ''}` +
            `${tech.effect.employeeSatisfaction ? `- Mitarbeiterzufriedenheit: +${tech.effect.employeeSatisfaction}\n` : ''}` +
            `${tech.effect.automation ? `- Automatisierungsgrad: +${tech.effect.automation}%\n` : ''}`
        };
      }
    } else if (points >= 5) {
      const bonus = 5000;
      setTotalAssets(prev => prev + bonus);
      return {
        message: `Sie haben eine Bonuszahlung erhalten!`,
        effects: `Auswirkungen:\n- Unternehmensvermögen: +${formatCurrency(bonus)}`
      };
    } else {
      const penalty = Math.floor(totalAssets * 0.05);
      setTotalAssets(prev => Math.max(0, prev - penalty));
      setCompany(prev => ({
        ...prev,
        customerSatisfaction: Math.max(0, prev.customerSatisfaction - 5),
        employeeSatisfaction: Math.max(0, prev.employeeSatisfaction - 5)
      }));
      return {
        message: `Ihre Entscheidung hatte negative Auswirkungen auf das Unternehmen.`,
        effects: `Auswirkungen:\n- Strafzahlung: -${formatCurrency(penalty)}\n- Kundenzufriedenheit: -5\n- Mitarbeiterzufriedenheit: -5`
      };
    }
  };

  useEffect(() => {
    if (gameStarted && !isYearEnd) {
      const gameLoop = setInterval(() => {
        setGameDay(prevDay => {
          const newDay = prevDay + 1;
          if ((newDay === 30 || (newDay > 30 && (newDay - lastChallengeDay) >= 60)) && !showChallenge) {
            generateChallenge();
          }
          return newDay;
        });
        setCurrentDate(prevDate => {
          const newDate = new Date(prevDate.getTime());
          newDate.setDate(newDate.getDate() + 1);
          
          if (newDate.getFullYear() > prevDate.getFullYear()) {
            setIsYearEnd(true);
            clearInterval(gameLoop);
          }
          
          return newDate;
        });

        setCompany(prev => {
          const satisfactionImpact = ((prev.customerSatisfaction + prev.employeeSatisfaction) / 200 - 0.5) * 0.02;
          const techImpact = implementedTechnologies.length * 0.01;
          const baseGrowthRate = -0.002;
          const dailyGrowthRate = baseGrowthRate + satisfactionImpact + techImpact;

          const newDailyRevenue = Math.max(0, prev.revenue * (1 + dailyGrowthRate));
          
          const dailySalaryPerEmployee = averageSalary / 30;
          const dailySalaryCosts = prev.employees * dailySalaryPerEmployee;
          
          const dailyTechCosts = implementedTechnologies.reduce((total, techId) => {
            const tech = technologyTreeData[techId];
            return total + (tech.monthlyTechCost || 0) / 30;
          }, 0);
          
          const variableExpenses = newDailyRevenue * 0.4;
          const dailyExpenses = dailySalaryCosts + dailyTechCosts + variableExpenses;
          
          const newDailyProfit = newDailyRevenue - dailyExpenses;
          
          const newYearlyRevenue = yearlyRevenue + newDailyRevenue;
          const newYearlyProfit = yearlyProfit + newDailyProfit;
          
          const newTotalAssets = Math.max(0, totalAssets + newDailyProfit);

          setYearlyRevenue(newYearlyRevenue);
          setYearlyProfit(newYearlyProfit);
          setTotalAssets(newTotalAssets);

          const targetEmployees = Math.max(1, Math.floor(newYearlyRevenue / 200000));
          const newEmployees = Math.min(targetEmployees, prev.employees + 1);
          
          const satisfactionIncrease = gameDay < 30 ? 0.5 : 0.1;
          const newCustomerSatisfaction = Math.min(100, prev.customerSatisfaction + satisfactionIncrease + (implementedTechnologies.length * 0.05));
          const newEmployeeSatisfaction = Math.max(0, Math.min(100, prev.employeeSatisfaction + (Math.random() - 0.5) + (implementedTechnologies.length * 0.02)));

          setHistoricalData(prevData => {
            const newData = [
              ...prevData, 
              {
                date: currentDate.toISOString().split('T')[0],
                revenue: newYearlyRevenue,
                profit: newYearlyProfit,
                employees: newEmployees,
                customerSatisfaction: newCustomerSatisfaction,
                employeeSatisfaction: newEmployeeSatisfaction,
              }
            ];
            return newData.slice(-30);
          });

          return {
            ...prev,
            revenue: newDailyRevenue,
            profit: newDailyProfit,
            employees: newEmployees,
            customerSatisfaction: newCustomerSatisfaction,
            employeeSatisfaction: newEmployeeSatisfaction,
          };
        });

        triggerRandomEvent();

        if (totalAssets > 1000000) {
          setGrowthStage(3);
          AnalyticsService.logCompanyMilestone('Growth Stage', 3);
        } else if (totalAssets > 500000) {
          setGrowthStage(2);
          AnalyticsService.logCompanyMilestone('Growth Stage', 2);
        }
      }, 1000);

      return () => clearInterval(gameLoop);
    }
  }, [gameStarted, isYearEnd, totalAssets, yearlyRevenue, yearlyProfit, implementedTechnologies, showChallenge, lastChallengeDay, averageSalary]);

  const handleYearEnd = () => {
    setAllYearsData(prev => [...prev, { 
      year: currentDate.getFullYear() - 1, 
      revenue: yearlyRevenue, 
      profit: yearlyProfit,
      employees: company.employees 
    }]);
    setPreviousYearData({ yearlyRevenue, yearlyProfit, employees: company.employees });
    
    setTotalAssets(prev => Math.max(0, prev + yearlyProfit));
    
    const newDailyRevenue = Math.max(1000, yearlyRevenue / 365);
    
    setCompany(prev => ({
      ...prev,
      revenue: newDailyRevenue,
    }));
    setYearlyRevenue(0);
    setYearlyProfit(0);
    
    const performanceRatio = yearlyProfit / yearlyRevenue;
    const satisfactionChange = performanceRatio * 10;
    
    setCompany(prev => ({
      ...prev,
      customerSatisfaction: Math.max(0, Math.min(100, prev.customerSatisfaction + satisfactionChange)),
      employeeSatisfaction: Math.max(0, Math.min(100, prev.employeeSatisfaction + satisfactionChange)),
    }));
    
    setIsYearEnd(false);
    setShowYearEndReport(false);
    AnalyticsService.logYearEnd(currentDate.getFullYear() - 1, yearlyRevenue, yearlyProfit);
  };

  const resetGame = () => {
    localStorage.removeItem('officeAutomationGame');
    setGameStarted(false);
    setCompany({
      name: '',
      industry: '',
      revenue: 0,
      profit: 0,
      customerSatisfaction: 50,
      employeeSatisfaction: 50,
      automationLevel: 0,
      employees: 5,
    });
    setImplementedTechnologies([]);
    setCurrentDate(new Date());
    setHistoricalData([]);
    setEventHistory([]);
    setGrowthStage(1);
    setYearlyRevenue(0);
    setYearlyProfit(0);
    setAllYearsData([]);
    setGameDay(0);
    setTotalAssets(200000);
    setLastChallengeDay(0);
    setTotalPoints(0);
    AnalyticsService.logGameEnd(totalPoints);
  };

  const calculateTechImpact = (techId) => {
    const tech = technologyTreeData[techId];
    let impact = {
      revenue: 0,
      customerSatisfaction: 0,
      employeeSatisfaction: 0,
      automationLevel: 0
    };

    if (tech.effect.productivity) {
      impact.revenue = yearlyRevenue * (tech.effect.productivity / 100);
    }
    if (tech.effect.customerSatisfaction) {
      impact.customerSatisfaction = tech.effect.customerSatisfaction;
    }
    if (tech.effect.employeeSatisfaction) {
      impact.employeeSatisfaction = tech.effect.employeeSatisfaction;
    }
    if (tech.effect.automation) {
      impact.automationLevel = tech.effect.automation;
    }

    return impact;
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount);
  };

  const Footer = () => (
    <div className="absolute bottom-0 right-0 left-0 bg-gray-800 bg-opacity-75 text-white p-2 text-xs">
      <p className="text-center">
        Ein Projekt von Damian Gorzkulla. 
        <a 
          href="https://www.gorzkulla.de/impressum/" 
          target="_blank" 
          rel="noopener noreferrer"
          className="ml-2 text-blue-300 hover:text-blue-100"
        >
          Impressum und Datenschutz
        </a>
      </p>
    </div>
  );

  if (!gameStarted) {
    return (
      <div className="relative min-h-screen">
        <StartScreen onStartGame={startGame} />
        <Footer />
      </div>
    );
  }

  return (
    <motion.div 
      className="relative min-h-screen bg-cover bg-center p-4 pb-16"
      style={{backgroundImage: `url(/images/company-growth-${growthStage}.jpg)`}}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 lg:col-span-3">
          <div className="bg-white bg-opacity-75 p-4 rounded-lg shadow-lg mb-4">
            <p className="text-lg font-bold">{currentDate.toLocaleDateString()}</p>
            <p>Tag im Jahr: {Math.floor((currentDate - new Date(currentDate.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24)}</p>
          </div>
          <CompanyMetrics 
            company={company} 
            yearlyRevenue={yearlyRevenue} 
            yearlyProfit={yearlyProfit}
            totalAssets={totalAssets}
          />
          <SalaryCosts employees={company.employees} averageSalary={averageSalary} />
        </div>

        <div className="col-span-12 lg:col-span-6">
          <TechnologyTree 
            implementedTech={implementedTechnologies}
            onImplement={handleImplementTechnology}
            onSelectTech={handleSelectTech}
            companyAssets={totalAssets}
          />
          <EventDisplay events={eventHistory} />
        </div>

        <div className="col-span-12 lg:col-span-3">
          <PerformanceChart historicalData={historicalData} />
        </div>

        <div className="col-span-12">
          <AutomationLevel level={company.automationLevel} />
        </div>
      </div>

      <button onClick={resetGame} className="bg-red-500 text-white px-4 py-2 rounded mt-4">Spiel zurücksetzen</button>
      <button onClick={() => setShowStatistics(true)} className="bg-blue-500 text-white px-4 py-2 rounded mt-4 ml-4">
        Geschäftsstatistiken
      </button>
      <button onClick={() => setShowTutorial(true)} className="bg-green-500 text-white px-4 py-2 rounded mt-4 ml-4">
        Tutorial
      </button>

      {selectedTech && (
        <TechInfoPopup
          tech={selectedTech}
          onClose={() => setSelectedTech(null)}
          onImplement={handleImplementTechnology}
          isImplemented={implementedTechnologies.includes(selectedTech.id)}
          implementedTech={implementedTechnologies}
          companyAssets={totalAssets}
        />
      )}

      {isYearEnd && (
        <YearEndReport 
          company={{
            ...company,
            yearlyRevenue,
            yearlyProfit,
            companyAssets: totalAssets,
          }}
          previousYear={previousYearData}
          implementedTech={implementedTechnologies.map(techId => ({
            ...technologyTreeData[techId],
            impact: calculateTechImpact(techId)
          }))}
          onClose={handleYearEnd}
        />
      )}

      {showStatistics && (
        <BusinessStatistics 
          data={allYearsData} 
          onClose={() => setShowStatistics(false)}
        />
      )}

      {showTutorial && (
        <GameTutorial onClose={() => setShowTutorial(false)} />
      )}

      {newEvent && (
        <div className="absolute top-4 left-4 bg-yellow-300 p-2 rounded shadow">
          <p>Neues Ereignis: {newEvent.title}</p>
        </div>
      )}

      {showChallenge && currentChallenge && (
        <ChallengePopup
          challenge={currentChallenge}
          onClose={() => {
            setShowChallenge(false);
            setCurrentChallenge(null);
          }}
          onSelect={(points) => {
            return handleChallengeReward(points);
          }}
        />
      )}

      <Footer />
    </motion.div>
  );
};

export default CompanySimulation;