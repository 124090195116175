export const technologyTreeData = {
  basicAutomation: {
    id: 'basicAutomation',
    name: "Grundlegende Automatisierung",
    description: "Einfache Automatisierung von Routineaufgaben.",
    cost: 5000,
    monthlyTechCost: 500,
    effect: {
      productivity: 10,
      automation: 15,
      employeeReduction: 1,
    },
    dependencies: [],
    children: ['office365', 'traditionalSoftware'],
    videoUrl: "",
  },
  office365: {
    id: 'office365',
    name: "Office 365",
    description: "Cloud-basierte Produktivitätssuite für Unternehmen.",
    cost: 10000,
    monthlyTechCost: 20, // Monatliche Kosten pro Mitarbeiter
    effect: {
      productivity: 20,
      collaboration: 25,
      automation: 10,
    },
    dependencies: ['basicAutomation'],
    children: ['aiChatbot', 'cloudMigration'],
    videoUrl: "",
  },
  aiChatbot: {
    id: 'aiChatbot',
    name: "KI Chatbot",
    description: "Intelligenter Chatbot für verbesserten Kundenservice.",
    cost: 15000,
    monthlyTechCost: 1000,
    effect: {
      customerSatisfaction: 30,
      efficiency: 25,
      automation: 20,
    },
    dependencies: ['office365'],
    children: [],
    videoUrl: "",
  },
  cloudMigration: {
    id: 'cloudMigration',
    name: "Cloud-Migration",
    description: "Umstellung der IT-Infrastruktur auf Cloud-Dienste.",
    cost: 25000,
    monthlyTechCost: 2000,
    effect: {
      productivity: 30,
      efficiency: 35,
      automation: 25,
    },
    dependencies: ['office365'],
    children: ['dataAnalytics'],
    videoUrl: "",
  },
  dataAnalytics: {
    id: 'dataAnalytics',
    name: "Datenanalyse-Tools",
    description: "Fortschrittliche Tools zur Analyse von Geschäftsdaten.",
    cost: 20000,
    monthlyTechCost: 1500,
    effect: {
      productivity: 25,
      efficiency: 30,
      automation: 15,
    },
    dependencies: ['cloudMigration'],
    children: [],
    videoUrl: "",
  },
  traditionalSoftware: {
    id: 'traditionalSoftware',
    name: "Bewährte Softwarelösungen",
    description: "Einsatz von etablierten, lokalen Softwarelösungen.",
    cost: 8000,
    monthlyTechCost: 800,
    effect: {
      productivity: -5,
      efficiency: -10,
      employeeSatisfaction: -5,
      automation: -5,
    },
    dependencies: ['basicAutomation'],
    children: ['paperBasedProcesses'],
    videoUrl: "",
  },
  paperBasedProcesses: {
    id: 'paperBasedProcesses',
    name: "Papierbasierte Prozesse",
    description: "Beibehaltung bewährter papierbasierten Arbeitsabläufe.",
    cost: 2000,
    monthlyTechCost: 300,
    effect: {
      productivity: -10,
      efficiency: -15,
      customerSatisfaction: -10,
      automation: -15,
    },
    dependencies: ['traditionalSoftware'],
    children: [],
    videoUrl: "",
  },
};