import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import CompanySimulation from './components/CompanySimulation';
import Leaderboard from './pages/Leaderboard';
import { logPageView } from './services/analyticsService';
import GoogleTagManager from './GoogleTagManager';

function RouteTracker() {
  const location = useLocation();
  
  React.useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);
  
  return null;
}

function App() {
  return (
    <Router>
      <GoogleTagManager />
      <RouteTracker />
      <div className="App">
        <Routes>
          <Route path="/" element={<Navigate to="/simulation" replace />} />
          <Route path="/simulation" element={<CompanySimulation />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;