import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { technologyTreeData } from './technologyTreeData';

const TechnologyNode = ({ tech, implementedTech, onSelectTech, level }) => {
  const isImplemented = implementedTech.includes(tech.id);
  const canImplement = tech.dependencies.every(dep => implementedTech.includes(dep));

  return (
    <div className={`flex flex-col items-center ${level > 0 ? 'mt-8' : ''}`}>
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className={`p-4 rounded-lg cursor-pointer ${
          isImplemented ? 'bg-green-500' : canImplement ? 'bg-blue-500' : 'bg-gray-400'
        } text-white relative w-48`}
        onClick={() => onSelectTech(tech)}
      >
        <img src={`/images/tech/${tech.id}.png`} alt={tech.name} className="w-full h-32 object-cover mb-2 rounded" />
        <h3 className="font-bold text-center text-sm">{tech.name}</h3>
      </motion.div>
      {tech.children && tech.children.length > 0 && (
        <div className="flex flex-col items-center mt-4">
          <div className="w-1 h-8 bg-gray-300"></div>
          <div className="flex space-x-4">
            {tech.children.map(childId => (
              <TechnologyNode
                key={childId}
                tech={technologyTreeData[childId]}
                implementedTech={implementedTech}
                onSelectTech={onSelectTech}
                level={level + 1}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const TechnologyTree = ({ implementedTech, onSelectTech }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const rootTechnologies = Object.values(technologyTreeData).filter(tech => tech.dependencies.length === 0);

  const handleExpandClick = () => {
    setIsExpanded(true);
  };

  const handleCloseClick = () => {
    setIsExpanded(false);
  };

  return (
    <>
      <div 
        className="bg-white bg-opacity-90 rounded-lg shadow-lg p-4 cursor-pointer hover:bg-opacity-100 transition-all duration-300"
        onClick={handleExpandClick}
      >
        <h2 className="text-2xl font-bold mb-4">Technologiebaum</h2>
        <p>Klicken Sie hier, um den vollständigen Technologiebaum anzuzeigen.</p>
      </div>

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
            <div className="bg-white rounded-lg shadow-xl p-8 m-4 max-w-4xl w-full max-h-[90vh] overflow-auto">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold">Vollständiger Technologiebaum</h2>
                <button 
                  onClick={handleCloseClick}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="flex justify-center space-x-8">
                {rootTechnologies.map(tech => (
                  <TechnologyNode
                    key={tech.id}
                    tech={tech}
                    implementedTech={implementedTech}
                    onSelectTech={onSelectTech}
                    level={0}
                  />
                ))}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default TechnologyTree;