import React from 'react';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import { technologyTreeData } from './technologyTreeData';

const TechInfoPopup = ({ tech, onClose, onImplement, isImplemented, implementedTech, companyAssets }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
    >
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">{tech.name}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X />
          </button>
        </div>
        <div className="mb-6 h-64 overflow-hidden rounded-lg">
          {tech.videoUrl ? (
            <iframe
              src={`https://player.vimeo.com/video/${tech.videoUrl.split('/').pop()}?autoplay=0&title=0&byline=0&portrait=0`}
              width="100%"
              height="100%"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            <img src={`/images/tech/${tech.id}.png`} alt={tech.name} className="w-full h-full object-cover" />
          )}
        </div>
        <p className="mb-4">{tech.description}</p>
        <div className="mt-4">
          <h3 className="font-bold">Abhängigkeiten:</h3>
          <ul>
            {tech.dependencies.map((depId) => (
              <li key={depId} className={implementedTech.includes(depId) ? 'text-green-500' : 'text-red-500'}>
                {technologyTreeData[depId].name}
                {!implementedTech.includes(depId) && ' (muss zuerst implementiert werden)'}
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-4 flex justify-between">
          {tech.courseUrl && (
            <a 
              href={tech.courseUrl} 
              target="_blank" 
              rel="noopener noreferrer"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
            >
              Zum Online-Kurs
            </a>
          )}
          {!isImplemented && (
            <button
              onClick={() => onImplement(tech.id)}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors"
              disabled={tech.dependencies.some(dep => !implementedTech.includes(dep)) || companyAssets < tech.cost}
            >
              Implementieren ({new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(tech.cost)})
            </button>
          )}
          {isImplemented && (
            <span className="text-green-500 font-semibold">Bereits implementiert</span>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default TechInfoPopup;