export const logPageView = (path) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'pageview',
      page: path
    });
    console.log('Pageview logged:', path);
  } else {
    console.error('dataLayer not found');
  }
};

export const logEvent = (category, action, label, value) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'custom_event',
      event_category: category,
      event_action: action,
      event_label: label,
      event_value: value
    });
    console.log('Event logged:', category, action, label, value);
  } else {
    console.error('dataLayer not found');
  }
};

export const setUserProperty = (name, value) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'set_user_property',
      user_property_name: name,
      user_property_value: value
    });
    console.log('User property set:', name, value);
  } else {
    console.error('dataLayer not found');
  }
};

export const logGameStart = () => logEvent('Game', 'Start', 'New Game');
export const logGameEnd = (totalScore) => logEvent('Game', 'End', 'Game Over', totalScore);
export const logLevelStart = (levelId) => logEvent('Level', 'Start', `Level ${levelId}`);
export const logLevelComplete = (levelId, score) => {
  logEvent('Level', 'Complete', `Level ${levelId}`, score);
  setUserProperty('lastCompletedLevel', levelId);
};
export const logLevelAbort = (levelId) => logEvent('Level', 'Abort', `Level ${levelId}`);
export const logTechnologyImplementation = (technologyId) => logEvent('Technology', 'Implement', technologyId);
export const logChallengeStart = (challengeId) => logEvent('Challenge', 'Start', challengeId);
export const logChallengeComplete = (challengeId, result) => logEvent('Challenge', 'Complete', challengeId, result);
export const logCompanyMilestone = (milestone, value) => logEvent('Company', 'Milestone', milestone, value);
export const logRandomEvent = (eventId) => logEvent('Random Event', 'Occur', eventId);
export const logYearEnd = (year, revenue, profit) => {
  logEvent('Company', 'Year End', `Year ${year}`, revenue);
  setUserProperty('companyRevenue', revenue);
  setUserProperty('companyProfit', profit);
};
export const logLeaderboardEntry = (score, rank) => logEvent('Leaderboard', 'New Entry', `Rank ${rank}`, score);