import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const formatCurrency = (value) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
};

const PerformanceChart = ({ historicalData }) => {
  return (
    <div className="bg-white bg-opacity-75 rounded-lg shadow-lg p-4 mb-4">
      <h2 className="text-xl font-bold mb-4">Unternehmensperformance</h2>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={historicalData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis yAxisId="left" orientation="left" stroke="#3b82f6" />
          <YAxis yAxisId="right" orientation="right" stroke="#10b981" />
          <Tooltip formatter={(value) => formatCurrency(value)} />
          <Legend />
          <Line yAxisId="left" type="monotone" dataKey="revenue" stroke="#3b82f6" name="Umsatz" />
          <Line yAxisId="right" type="monotone" dataKey="profit" stroke="#10b981" name="Gewinn" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PerformanceChart;