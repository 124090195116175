import React, { useState } from 'react';

const ChallengePopup = ({ challenge, onClose, onSelect }) => {
  const [showExpertTip, setShowExpertTip] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [rewardMessage, setRewardMessage] = useState('');
  const [rewardEffects, setRewardEffects] = useState('');
  
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setShowFeedback(true);
    const reward = onSelect(option.points);
    setRewardMessage(reward.message);
    setRewardEffects(reward.effects);
  };

  const getFeedback = (points) => {
    if (points >= 8) return "Ausgezeichnete Wahl! Diese Entscheidung wird Ihr Unternehmen signifikant voranbringen.";
    if (points >= 5) return "Gute Entscheidung. Sie sind auf dem richtigen Weg zur Verbesserung Ihres Unternehmens.";
    return "Eine vorsichtige Wahl. Es gibt noch Raum für Optimierung in Ihrem Ansatz.";
  };
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-2xl w-full">
        <h2 className="text-2xl font-bold mb-4">{challenge.title}</h2>
        <p className="mb-4">{challenge.description}</p>
        {!showFeedback && (
          <div className="space-y-2">
            {challenge.options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleOptionSelect(option)}
                className="w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                {option.text}
              </button>
            ))}
          </div>
        )}
        {showFeedback && (
          <div className="mt-4 p-4 bg-green-100 rounded">
            <p><strong>Feedback:</strong> {getFeedback(selectedOption.points)}</p>
            <p className="mt-2 font-bold">{rewardMessage}</p>
            <pre className="mt-2 whitespace-pre-wrap">{rewardEffects}</pre>
          </div>
        )}
        {!showFeedback && (
          <button
            onClick={() => setShowExpertTip(true)}
            className="mt-4 p-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            Experten-Tipp anfordern
          </button>
        )}
        {showExpertTip && (
          <div className="mt-4 p-4 bg-yellow-100 rounded">
            <p><strong>Experten-Tipp:</strong> {challenge.expertTip}</p>
            <p className="mt-2 text-sm">Für eine maßgeschneiderte Beratung zu diesem Thema, besuchen Sie unsere Website oder buchen Sie einen Online-Kurs.</p>
          </div>
        )}
        <button 
          onClick={onClose} 
          className="mt-4 p-2 bg-gray-300 rounded"
        >
          {showFeedback ? "Herausforderung abschließen" : "Schließen"}
        </button>
      </div>
    </div>
  );
};

export default ChallengePopup;