import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { TrendingUp, DollarSign, Users, Info } from 'lucide-react';

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount);
};

const Metric = ({ title, value, icon, color }) => (
  <motion.div 
    className={`bg-white p-4 rounded-lg shadow-lg border-l-4 border-${color}-500`}
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="flex items-center justify-between">
      <div>
        <h3 className="text-sm font-semibold text-gray-600">{title}</h3>
        <p className={`text-lg font-bold text-${color}-500`}>{value}</p>
      </div>
      <div className={`bg-${color}-100 p-2 rounded-full`}>
        {icon}
      </div>
    </div>
  </motion.div>
);

const DetailView = ({ company, yearlyRevenue, yearlyProfit, totalAssets, onClose }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.8 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.8 }}
    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
  >
    <div className="bg-white p-6 rounded-lg max-w-md w-full">
      <h2 className="text-xl font-bold mb-4">Detaillierte Kennzahlen</h2>
      <ul className="space-y-2">
        <li>Täglicher Umsatz: {formatCurrency(company.revenue)}</li>
        <li>Jahresumsatz: {formatCurrency(yearlyRevenue)}</li>
        <li>Jahresgewinn: {formatCurrency(yearlyProfit)}</li>
        <li>Gesamtvermögen: {formatCurrency(totalAssets)}</li>
        <li>Kundenzufriedenheit: {company.customerSatisfaction.toFixed(1)}%</li>
        <li>Mitarbeiterzufriedenheit: {company.employeeSatisfaction.toFixed(1)}%</li>
        <li>Mitarbeiter: {company.employees}</li>
      </ul>
      <button onClick={onClose} className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
        Schließen
      </button>
    </div>
  </motion.div>
);

const CompanyMetrics = ({ company, yearlyRevenue, yearlyProfit, totalAssets }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="relative">
      <div className="grid grid-cols-1 gap-4 bg-opacity-75">
        <Metric
          title="Jahresumsatz" 
          value={formatCurrency(yearlyRevenue)} 
          icon={<TrendingUp className="w-6 h-6" />} 
          color="green"
        />
        <Metric 
          title="Gesamtvermögen" 
          value={formatCurrency(totalAssets)} 
          icon={<DollarSign className="w-6 h-6" />} 
          color="purple"
        />
        <Metric 
          title="Mitarbeiter" 
          value={company.employees} 
          icon={<Users className="w-6 h-6" />} 
          color="blue"
        />
      </div>
      <button 
        onClick={() => setShowDetails(true)} 
        className="absolute top-0 right-0 bg-gray-200 p-2 rounded-full hover:bg-gray-300"
      >
        <Info className="w-5 h-5" />
      </button>
      {showDetails && (
        <DetailView 
          company={company}
          yearlyRevenue={yearlyRevenue}
          yearlyProfit={yearlyProfit}
          totalAssets={totalAssets}
          onClose={() => setShowDetails(false)}
        />
      )}
    </div>
  );
};

export default CompanyMetrics;