import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const EventPopup = ({ event, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
      <h3 className="text-xl font-bold mb-2">{event.title}</h3>
      <p className="mb-4">{event.description}</p>
      <h4 className="font-semibold mb-2">Auswirkungen:</h4>
      <ul className="list-disc pl-5 mb-4">
        {event.effects.split(', ').map((effect, index) => (
          <li key={index} className="mb-1">{effect}</li>
        ))}
      </ul>
      <button 
        onClick={onClose}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Schließen
      </button>
    </div>
  </div>
);

const EventDisplay = ({ events }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  return (
    <motion.div 
      className="bg-white bg-opacity-75 p-4 rounded-lg shadow-lg mt-4"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h3 className="text-xl font-bold mb-2">Aktuelle Ereignisse</h3>
      <div className="space-y-2">
        {events.slice(-5).reverse().map((event, index) => (
          <motion.div 
            key={index}
            className={`p-2 rounded ${event.type === 'positive' ? 'bg-green-100' : 'bg-red-100'} cursor-pointer hover:shadow-md transition-shadow duration-200`}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.1 }}
            onClick={() => setSelectedEvent(event)}
          >
            <p className="font-semibold">{event.title}</p>
            <p className="text-sm">{event.description}</p>
            <p className="text-xs mt-1 text-gray-600">{new Date(event.date).toLocaleDateString()}</p>
          </motion.div>
        ))}
      </div>
      <AnimatePresence>
        {selectedEvent && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <EventPopup event={selectedEvent} onClose={() => setSelectedEvent(null)} />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default EventDisplay;