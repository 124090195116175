import React from 'react';
import { motion } from 'framer-motion';

const YearEndReport = ({ company, previousYear, implementedTech, onClose }) => {
  const calculateGrowth = (current, previous) => {
    if (previous === undefined || previous === null || previous === 0) return 'N/A';
    return ((current - previous) / previous * 100).toFixed(2);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount);
  };

  const formatPercent = (number) => {
    if (number === undefined || number === null) return 'N/A';
    return number.toFixed(2) + '%';
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Jahresabschluss {new Date().getFullYear()}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <h3 className="font-semibold">Jahresumsatz</h3>
            <p>{formatCurrency(company.yearlyRevenue)} (+{calculateGrowth(company.yearlyRevenue, previousYear?.yearlyRevenue)}%)</p>
          </div>
          <div>
            <h3 className="font-semibold">Jahresgewinn</h3>
            <p>{formatCurrency(company.yearlyProfit)} (+{calculateGrowth(company.yearlyProfit, previousYear?.yearlyProfit)}%)</p>
          </div>
          <div>
            <h3 className="font-semibold">Kundenzufriedenheit</h3>
            <p>{formatPercent(company.customerSatisfaction)} (+{calculateGrowth(company.customerSatisfaction, previousYear?.customerSatisfaction)}%)</p>
          </div>
          <div>
            <h3 className="font-semibold">Mitarbeiterzufriedenheit</h3>
            <p>{formatPercent(company.employeeSatisfaction)} (+{calculateGrowth(company.employeeSatisfaction, previousYear?.employeeSatisfaction)}%)</p>
          </div>
        </div>

        <div className="mb-6">
          <h3 className="font-semibold">Gesamtvermögen</h3>
          <p>{formatCurrency(company.companyAssets)}</p>
        </div>

        <h3 className="font-semibold mb-2">Implementierte Technologien:</h3>
        <ul className="list-disc pl-5 mb-4">
          {implementedTech.map(tech => (
            <li key={tech.id}>
              <strong>{tech.name}</strong>
              <p>{tech.description}</p>
              <p>Auswirkungen:</p>
              <ul className="list-disc pl-5">
                {tech.impact.revenue > 0 && <li>Umsatzsteigerung: {formatCurrency(tech.impact.revenue)}</li>}
                {tech.impact.customerSatisfaction > 0 && <li>Kundenzufriedenheit: +{tech.impact.customerSatisfaction}%</li>}
                {tech.impact.employeeSatisfaction > 0 && <li>Mitarbeiterzufriedenheit: +{tech.impact.employeeSatisfaction}%</li>}
                {tech.impact.automationLevel > 0 && <li>Automatisierungsgrad: +{tech.impact.automationLevel}%</li>}
              </ul>
            </li>
          ))}
        </ul>

        <h3 className="font-semibold mb-2">Automatisierungsgrad:</h3>
        <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
          <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${company.automationLevel || 0}%` }}></div>
        </div>
        <p>{formatPercent(company.automationLevel)} (+{calculateGrowth(company.automationLevel, previousYear?.automationLevel)}%)</p>

        <button 
          onClick={onClose}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
        >
          Neues Geschäftsjahr beginnen
        </button>
      </div>
    </motion.div>
  );
};

export default YearEndReport;