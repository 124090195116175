import React from 'react';

const SalaryCosts = ({ employees, averageSalary }) => {
  const monthlyCosts = employees * averageSalary;
  const yearlyCosts = monthlyCosts * 12;

  return (
    <div className="bg-white bg-opacity-75 p-4 rounded-lg shadow-lg mb-4">
      <h3 className="text-lg font-bold mb-2">Personalkosten</h3>
      <p>Anzahl Mitarbeiter: {employees}</p>
      <p>Durchschnittsgehalt: {averageSalary.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}/Monat</p>
      <p>Monatliche Gesamtkosten: {monthlyCosts.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</p>
      <p>Jährliche Gesamtkosten: {yearlyCosts.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</p>
    </div>
  );
};

export default SalaryCosts;