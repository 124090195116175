export const events = [
  {
    type: 'positive',
    title: 'Viraler Social Media Post',
    description: 'Ein Post über Ihr Unternehmen geht viral! Die Kundengewinnung steigt.',
    effect: {
      umsatz: 0.15,
      kundenzufriedenheit: 10,
    }
  },
  {
    type: 'negative',
    title: 'Serverausfall',
    description: 'Ein unerwarteter Serverausfall stört Ihre Geschäftsprozesse.',
    effect: {
      umsatz: -0.1,
      kundenzufriedenheit: -20,
      mitarbeiterzufriedenheit: -15,
    }
  },
  {
    type: 'positive',
    title: 'Innovationspreis',
    description: 'Ihr Unternehmen gewinnt einen Innovationspreis!',
    effect: {
      umsatz: 0.2,
      mitarbeiterzufriedenheit: 15,
    }
  },
  {
    type: 'negative',
    title: 'Datenschutzverstoß',
    description: 'Ein schwerwiegender Datenschutzverstoß wurde aufgedeckt.',
    effect: {
      strafe: (company) => {
        const basePenalty = 0.15; // 15% des Gesamtvermögens als Basisstrafe
        const automationFactor = 1 - (company.automationLevel / 100); // Je niedriger die Automatisierung, desto höher der Faktor
        return basePenalty * automationFactor;
      },
      kundenzufriedenheit: -20,
    }
  },
  {
    type: 'negative',
    title: 'Cybersicherheitsvorfall',
    description: 'Ein Hacker-Angriff hat sensible Unternehmensdaten kompromittiert.',
    effect: {
      strafe: (company) => {
        const basePenalty = 0.2; // 20% des Gesamtvermögens als Basisstrafe
        const automationFactor = 1 - (company.auatomationLevel / 100);
        return basePenalty * automationFactor;
      },
      kundenzufriedenheit: -25,
      mitarbeiterzufriedenheit: -15,
    }
  },
  {
    type: 'negative',
    title: 'Kartellrechtsverstoß',
    description: 'Das Unternehmen wurde wegen Kartellrechtsverstößen verklagt.',
    effect: {
      umsatz: -0.2,
      kundenzufriedenheit: -15,
      strafe: 0.15 // 15% des Gesamtvermögens als Strafe
    }
  },
  {
    type: 'positive',
    title: 'Erfolgreiche Produkteinführung',
    description: 'Ihr neues Produkt wird von den Kunden sehr gut angenommen.',
    effect: {
      umsatz: 0.25,
      kundenzufriedenheit: 20,
    }
  },
  {
    type: 'positive',
    title: 'Positive Medienberichterstattung',
    description: 'Ein großes Magazin veröffentlicht einen lobenden Artikel über Ihr Unternehmen.',
    effect: {
      umsatz: 0.12,
      kundenzufriedenheit: 15,
    }
  },
];