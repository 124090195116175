import React, { useState } from 'react';
import { motion } from 'framer-motion';

const industries = [
  { id: 'tech', name: 'Technologie', startCapital: 200000 },
  { id: 'retail', name: 'Einzelhandel', startCapital: 150000 },
  { id: 'service', name: 'Dienstleistung', startCapital: 100000 },
];

const StartScreen = ({ onStartGame }) => {
  const [companyName, setCompanyName] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState(industries[0]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (companyName && selectedIndustry) {
      onStartGame(companyName, selectedIndustry);
    }
  };

  return (
    <motion.div 
      className="min-h-screen flex items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: "url('/images/office-background.jpg')" }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="bg-white bg-opacity-90 p-8 rounded-lg shadow-xl max-w-md w-full">
        <h1 className="text-3xl font-bold mb-6 text-center">Office Automation Adventure</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="companyName">
              Unternehmensname
            </label>
            <input 
              type="text"
              id="companyName"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="industry">
              Branche
            </label>
            <select
              id="industry"
              value={selectedIndustry.id}
              onChange={(e) => setSelectedIndustry(industries.find(i => i.id === e.target.value))}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              {industries.map(industry => (
                <option key={industry.id} value={industry.id}>
                  {industry.name} - Startkapital: {industry.startCapital.toLocaleString('de-DE')}€
                </option>
              ))}
            </select>
          </div>
          <button 
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
          >
            Spiel starten
          </button>
        </form>
      </div>
    </motion.div>
  );
};

export default StartScreen;