import React, { useEffect, useState } from 'react';
import * as AnalyticsService from '../services/analyticsService';

const Leaderboard = () => {
  const [leaderboardData, setLeaderboardData] = useState([
    { name: 'Spieler 1', score: 1000 },
    { name: 'Spieler 2', score: 950 },
    { name: 'Spieler 3', score: 900 },
  ]);

  useEffect(() => {
    AnalyticsService.logPageView();
    // Hier würden Sie normalerweise die Bestenliste von einem Server abrufen
    // Für dieses Beispiel verwenden wir statische Daten
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Bestenliste</h2>
      <ul className="list-decimal pl-5">
        {leaderboardData.map((player, index) => (
          <li key={index} className="mb-2">
            {player.name} - {player.score} Punkte
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Leaderboard;